function eventToCoordinates(event) { 
	if ('TouchEvent' in window && event instanceof TouchEvent) {
		let touch
		if (event.touches.length != 0 ) {
			touch = event.touches[0] 
		} else if (event.changedTouches.length != 0) {
			touch = event.changedTouches[0]
		} else {
			return {x: undefined, y: undefined}
		}
		return {x: touch.clientX, y: touch.clientY}
	}

	const isPointerEvent = 'PointerEvent' in window && event instanceof PointerEvent
	return isPointerEvent ? 
		{x: event.clientX, y: event.clientY}:
		{x: event.pageX, y: event.pageY} 
}

function eventToOffset(event){
	if ('TouchEvent' in window && event instanceof TouchEvent) {
		let touch
		if (event.touches.length != 0 ) {
			touch = event.touches[0] 
		} else if (event.changedTouches.length != 0) {
			touch = event.changedTouches[0]
		} else {
			return {x: undefined, y: undefined}
		}
		const rect = event.target.getBoundingClientRect()
		return {x: touch.clientX - rect.left, y: touch.clientY - rect.top}
	}

	return {x: event.offsetX, y: event.offsetY} 
}

function eventToPageCoordinates(event){
	if ('TouchEvent' in window && event instanceof TouchEvent) {
		let touch
		if (event.touches.length != 0 ) {
			touch = event.touches[0] 
		} else if (event.changedTouches.length != 0) {
			touch = event.changedTouches[0]
		} else {
			return {x: undefined, y: undefined}
		}
		return {x: touch.pageX, y: touch.pageY}
	}

	return {x: event.pageX, y: event.pageY} 
}

function topPositionByTime(hour, minute, cellHeight){
	let topPosition = hour * cellHeight + minute * cellHeight / 60
	return Math.floor(topPosition)
}

/**
 * Calculate the top position of the slot by the time
 * @param {Date} slotStartAt - slot start time
 * @param {CalendarWorkTime} workTime - work time bounds
 * @param {number} cellHeight - height of the hour cell in px
 * @returns {number} - top position in px
 */
function calcSlotCardPosY(slotStartAt, workTime, cellHeight){
	const {hour, minute} = workTime.startTime()
	const slotHour = slotStartAt.getHours()
	const slotMinute = slotStartAt.getMinutes()
	
	const workStartAlign = topPositionByTime(hour, minute, cellHeight)
	const relativeTop = topPositionByTime(slotHour, slotMinute, cellHeight) 
	
	return relativeTop - workStartAlign
}

export {
	calcSlotCardPosY,
	eventToCoordinates,
	eventToOffset,
	eventToPageCoordinates
} 