import CalendarHeaderItem from "./calendarHeaderItem"
import { getCurrentWeek, nextDate, isEqualDate, dateDifferenceInDays, getCurrentWeekV2, dateRange } from "./date"
import Vue from "vue"

class CalendarHeader {

	constructor ({selectedDate, columnCount, from, to}) {
		this.selectedDate = selectedDate
		this.pinnedItem = null 
		this.supportDates = []
		this.columnCount = columnCount
		if (from && to){
			this.week = dateRange(from, to)
			.map(date => new CalendarHeaderItem(date, 1))
			this.from = from
			this.to = to
		} else {
			this.week = getCurrentWeekV2(this.selectedDate, columnCount)
				.map(date => new CalendarHeaderItem(date, 1))
			this.from = this.week[0].date
			this.to = this.week[this.week.length - 1].date
		}
		this.updateSlots()
	}

	getAllWeek(){
		return this.week.concat(this.supportDates)
			.sort((itemA, itemB) => itemA.date - itemB.date) 
	}
	
	updateSlots(){
	}

	updateWeek(index, date){
		if (this.week[index])
			Vue.set(this.week[index], "date", date)
	}

	updatePinnedItem(value=null){

		// To unset pinned item need to select them 
		if (!value && isEqualDate(this.selectedDate, this.pinnedItem.date)){
			let newSelectedDate
			let minRange = 0
			this.week.forEach((headerItem, index) => {
				let range = dateDifferenceInDays(headerItem.date, this.selectedDate)
				if (index == 0 || (index != 0 && range < minRange)){
					minRange = range
					newSelectedDate = headerItem.date
				}
			})
			this.selectedDate = newSelectedDate
			this.pinnedItem = null
			return 
		}
		this.pinnedItem = new CalendarHeaderItem(value, 1)
	}

	isSupportDate(date){
		return this.supportDates
			.find((headerItem) => isEqualDate(headerItem.date, date)) !== undefined
	}

	updateColumnCount(columnCount){
		let middleItem = this.week[Math.floor(this.week.length/2)]
		this.week = getCurrentWeek(middleItem.date, columnCount)
		.map(date => {
			let supportItem = this.supportDates
				.find(supportItem => isEqualDate(supportItem.date, date))
			if (supportItem){
				supportItem.scale = 1					
				return supportItem
			}
			return new CalendarHeaderItem(date, 1)
		})
		this.selectedDate = middleItem.date
		this.columnCount = columnCount
		this.supportDates = []
	}

	firstItem(){
		return this.week[0]
	}

	firstDate(){
		return this.week[0].date
	}

	lastItem(){
		return this.week[this.week.length - 1]
	}

	lastDate(){
		return this.week[this.week.length - 1].date
	}

	includes(date){
		return this.week
			.find((headerItem) => isEqualDate(headerItem.date, date)) !== undefined
	}

	refresh(selectedDate){
		this.selectedDate = selectedDate
		this.week = getCurrentWeekV2(this.selectedDate, this.columnCount)
			.map(date => new CalendarHeaderItem(date, 1))
		if (this.columnCount != 7)
			this.updateColumnCount(this.columnCount)
		return new Promise((resolve) => {
			resolve(this.week)
		})
	}

	visibleItems(){
		return this.week.filter(headerItem => headerItem.scale > 0)
	}

	updatePinnedDatePlace(){
		// Add the attached date if not exists
		let visibleItems
		let pinnedDateIsVisible = visibleItems
			.find(headerItem => isEqualDate(headerItem.date, this.pinnedItem))

		if (this.pinnedDate && !pinnedDateIsVisible){
			console.log("TABLE HEADER : update pinned date")
			let putToFirst = this.pinnedDate < visibleItems[0].date
			let putToLast = this.pinnedDate > visibleItems[visibleItems.length - 1].date
			if (putToFirst){
				this.updateWeek(this.columnCount - 1, this.pinnedDate)
			} 
			if (putToLast){
				this.updateWeek(0, this.pinnedDate)
			}
			if (!this.includes(this.selectedDate)){
				this.selectedDate = new Date(this.pinnedDate.getTime()) 
			}
		}
	}

	selectedItem(){
		if (this.pinnedItem && isEqualDate(this.selectedDate, this.pinnedItem.date)){
			return this.pinnedItem
		}

		return this.week.find((headerItem) => {
			return isEqualDate(headerItem.date, this.selectedDate)
		})
	}

	// 1 17 18 19 20 21 22 23
	// 1 2 3 4 5 6 7 22

	rightSide(){
		let isAppend = false
		let right 
		if (this.pinnedItem && 
			!this.includes(this.pinnedItem.date) &&	
			isEqualDate(this.pinnedItem.date, this.selectedDate)){

			if (this.pinnedItem.date < this.firstDate()){
				right = this.getAllWeek()
			}
			if (this.pinnedItem.date > this.lastDate()){
				right = []
			}
		} 
		else {
			right = this.getAllWeek().filter(headerItem => {
				if (isEqualDate(headerItem.date, this.selectedDate))
				{
					isAppend = true
					return
				}
				if (headerItem.scale == 0)
					return false
				return isAppend	
			})
		}

		// If date is pinned
		let lastDate = right[right.length - 1] ? 
			right[right.length - 1].date :
			this.selectedDate
		
		if (this.pinnedItem && 
			!isEqualDate(this.selectedDate, this.pinnedItem.date) &&
			!this.includes(this.pinnedItem.date) && 
			this.pinnedItem.date > lastDate){

			if (right.length != 0){
				let isIncluded = right.find(headerItem => {
					return isEqualDate(headerItem.date, this.pinnedItem.date)
				})
				
				if (isIncluded){
					return right
				}
	
				// right[right.length - 1] = this.pinnedItem
				right.push(this.pinnedItem)
			}
			else {
				this.selectedDate = this.pinnedItem.date
			}
		}
		return right
	}
	leftSide(){
		let isAppend = true
		let left

		// 1 17 18 19 20 21 22 23
		// 1 2 3 4 5 6 7 22

		if (this.pinnedItem && 
			!this.includes(this.pinnedItem.date) &&	
			isEqualDate(this.pinnedItem.date, this.selectedDate)){
			if (this.pinnedItem.date > this.firstDate()){
				left = this.getAllWeek()
			}
			if (this.pinnedItem.date < this.lastDate()){
				left = []
			}
		} 
		else {
			left = this.getAllWeek().filter(headerItem => {
				if (isEqualDate(headerItem.date, this.selectedDate))
					isAppend = false
				if (headerItem.scale == 0)
					return false
				return isAppend	
			})
		}

		let firstDate = left[0] ?
			left[0].date :
			this.selectedDate
			
		if (this.pinnedItem && 
			!isEqualDate(this.selectedDate, this.pinnedItem.date) &&
			!this.includes(this.pinnedItem.date) && 
			this.pinnedItem.date < firstDate){

			if (left.length != 0){
				let isIncluded = left.find(headerItem => {
					return isEqualDate(headerItem.date, this.pinnedItem.date)
				})
				
				if (isIncluded){
					return left
				}
	
				// left[0] = this.pinnedItem
				left.unshift(this.pinnedItem)
			}
			else {
				this.selectedDate = this.pinnedItem.date
			}
		}

		return left
	}

	pushRight(count){
		let newDate
		
		for (let i = 0; i < count; i++)
		{
			newDate = nextDate(this.firstDate(),-1)
			this.week.unshift(new CalendarHeaderItem(newDate, 1))
			this.week.pop()
		}

		if (!this.includes(this.selectedDate)){
			let visibleItems = this.visibleItems()
			this.selectedDate = visibleItems[visibleItems.length - 1].date
		}

		console.log('visible count :>> ', this.visibleItems().length);

		return new Promise((resolve) => {
			resolve(this.week)
		})
	}

	pushLeft(count){
		let newDate

		
		for (let i = 0; i < count; i++){
			newDate = nextDate(this.lastDate(), 1)
			this.week.push(new CalendarHeaderItem(newDate, 1))
			this.week.shift()
		}


		if (!this.includes(this.selectedDate)){
			let visibleItems = this.visibleItems()
			this.selectedDate = visibleItems[0].date
		}
		
		return new Promise((resolve) => {
			resolve(this.week)
		})
	}

	updateSupportDates(columnCount){
		let futureWeek = getCurrentWeek(this.selectedDate, columnCount)
		let newHeaderItems = futureWeek
			.filter(date => !this.includes(date))
			.map(date => new CalendarHeaderItem(date, 0.2))
		this.supportDates = newHeaderItems 
	}

	isPinnedDate(date){
		if (!date || !this.pinnedItem)
			return false
		return isEqualDate(date, this.pinnedItem.date)
	}
}

export default CalendarHeader