<template>
	<span>
		<DatePoint :date="rangeBorders.start" 
			:index="1"
			:pairedIndex="daysCount == 1 ? undefined : 2"
			:baseLeft="baseLeft" 
			:daysCount="daysCount"
			:itemsContainer="itemsContainer" 
			:weekItemWidth="weekItemWidth" 
			:weekItemHeight="weekItemHeight"
			:deltaDates="deltaDates"/>
		
		<DatePoint v-if="daysCount != 1" :date="rangeBorders.end"
			:index="2"
			:pairedIndex="1"
			:baseLeft="baseLeft" 
			:daysCount="daysCount"
			:itemsContainer="itemsContainer" 
			:weekItemWidth="weekItemWidth" 
			:weekItemHeight="weekItemHeight"
			:deltaDates="deltaDates"/>
	</span>
</template>

<script>
import CalendarMonth from '../../../../scripts/calendarMonth';
import CalendarWeek from '../../../../scripts/calendarWeek';
import { isEqualDate, nextDate } from '../../../../scripts/date';
import DatePoint from './DatePoint.vue';

export default {
	name: 'WeekSelectedDays',
	components: {
		DatePoint,
	},
	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		daysCount: {
			type: Number,
			default: 1,
		},
		currentRange: {	
			type: CalendarWeek|CalendarMonth,
			required: true,
		},
		itemsContainer: {
			type: HTMLElement,
		},
		collapseStep: {
			type: Number,
			required: true,
		},
		carouselIndex: {
			type: Number,
			required: true,
		},
		deltaDates: {
			type: Number,
		}
	},
	computed: {
		weekItem(){
			return document.querySelector('.schedule-date')
		},

		baseLeft(){
			if (!this.itemsContainer)
				return 0
			const containerWidth = this.itemsContainer.offsetWidth
			return containerWidth * this.carouselIndex
		},
		selectedWeekDay(){
			const value = this.selectedDate.getDay()
			return value == 0 ? 6 : value - 1
		},
		rangeBorders(){
			return {
				start: new Date(this.selectedDate),
				end: nextDate(this.selectedDate, this.daysCount - 1)
			}
		},
	},

	data() {
		return {
			weekItemWidth: 0,
			weekItemHeight: 0,
			updateInterval: null,
			isMounted: false,
		}
	},

	mounted() {
		this.isMounted = true
		this.setupSizes()
	},

	beforeDestroy() {
		if (this.updateInterval) {
			clearInterval(this.updateInterval)
		}
	},

	methods:{

		setupSizes(){
			this.updateInterval = setInterval(() => {
				const size = this.getItemSize()
				if (size && size.width != null && size.width > 10) {
					this.weekItemWidth = Number(size.width.toFixed(1))
					this.weekItemHeight = Number(size.height.toFixed(1))
					clearInterval(this.updateInterval)
				}
			})
		},

		getItemSize(){
			if (!this.itemsContainer)
				return null

			const scheduleDates = Array.from(this.itemsContainer.querySelectorAll('.schedule-date'))
			if (scheduleDates.length == 0)
				return null
			const visibleScheduleDate = scheduleDates.find(elem => elem.style.display != 'none' && elem.offsetWidth)
			if (!visibleScheduleDate)
				return null
			const rect = visibleScheduleDate.getBoundingClientRect()
			return rect
		},

	}
}
</script>

<style scoped>
.selected-date-range-mark{
	position: absolute;
	height: calc(100% - 4px);
	border: 1px solid  #0075ff;
	box-shadow: 0 0 0 1.5px #adcdf3;
	border-radius: 4px;
	display: none;
	/* cubic-bezier(0.175, 0.885, 0.32, 1.275) */
	/* transition: all .3s ease; */
}

</style>