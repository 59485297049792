<template>
	<div class="calendar-v2" 
	:id="`calendar-v2-${selectedDate.toLocaleDateString()}`"
	ref="calendar">
		<!-- <div class="support-message" v-if="supportMessage">
			{{ supportMessage }}
		</div>
		 -->
		<ScrollableTable ref="table"
		:selectedDate="selectedDate" 
		:columns="calendarColumnItems"	
		:columnCount="columnCount"
		:hoursWidth="hoursWidth"
		@on-select-date="onSelectDate"
		@on-scroll-x="({deltaX, deltaDates}) => $emit('on-scroll-x', {deltaX, deltaDates})"
		@on-scroll-start="(ev) => $emit('on-scroll-start', ev)"
		@on-scroll-move="(ev) => $emit('on-scroll-move', ev)"
		@on-scroll-end="(ev) => $emit('on-scroll-end', ev)">
			<template v-slot:header>
				<CalendarDateTitle v-if="isShowDateTitles"
					:selectedDate="selectedDate"
					:columnCount="columnCount"
					:columnWidth="columnWidthRaw"
					:fromDate="fromDate"
					:toDate="toDate"
				/> 
			</template>
			<template v-slot:hours>
				<Hours ref="hours"
					:workTime="workTime"
					:cellHeight="cellHeight"
				/>
			</template>
			<template v-slot:columns>
				<Column
					v-for="column in calendarColumnItems"
					:key="`column-date-${column.date.toLocaleDateString()}`"
					:column="column"
					:cellHeight="cellHeight"
					:slotCollection="slotCollection"
					:slotContainer="slotContainer"
					:workTime="workTime.projection(column.date)"
					:columnCount="columnCount"
					:isEditable="isEditable"
					:width="columnWidthRaw"
				/>
			</template>
		</ScrollableTable>
	</div>
		<!-- Past time backdrop -->
	<!-- <PastTimeBackdrop
		:tableHeader="tableHeader"
		:workTime="workTime"
		:container="$el"
		:columnCount="columnCount"
	/> -->


</template>

<script>
import { dateIsPast, isEqualDate, pair } from '../../scripts/date';

import CalendarWorkTime from '../../scripts/calendarWorkTime';
import Column from './Column.vue';
import CalendarHeader from '../../scripts/calendarHeader';
import SlotCollection from '../../scripts/slotCollection';
import { getColumnWidth } from '../../scripts/column';

import Hours from './Hours.vue';
import CalendarDateTitle from './CalendarDateTitle.vue';
import PastTimeBackdrop from './PastTimeBackdrop.vue';
import ScrollableTable from './ScrollableTable.vue';
import TimePeriod from '../../scripts/timePeriod';

export default {
	name: 'CalendarV2',
	emits: [
		'on-select-date',
		'on-scroll-x',
		'on-scroll-start',
		'on-scroll-move',
		'on-scroll-end',
	],

	components: {
		Column,
		Hours,
		CalendarDateTitle,
		PastTimeBackdrop,
		ScrollableTable
	},

	props: {

		calendarUid: {
			type: String,
			required: true,
		},

		selectedDate: {
			type: Date,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		slotCollection : {
			type: SlotCollection,
			required: true,
		},

		columnCount: {
			type: Number,
			default: 1,
			validation: (count) => {
				return count >= 1 && count <= 7
			},
		},

		cellHeight: {
			type: Number,
		},
		dateViewRange: {
			type: TimePeriod,
			required: true,
		},

		isSyncScroll: {
			type: Boolean,
			default: false,
		},
		isShowDateTitles: {
			type: Boolean,
			default: true,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		defaultColumnWidth() {
			return getColumnWidth(this.columnCount, false, 45) + 'px';
		},
		selectedColumnWidth() {
			return getColumnWidth(this.columnCount, true, 45) + 'px';
		},
		supportMessage(){
			const rangeStart = new Date(this.selectedDate)
			const rangeEnd = new Date(this.selectedDate)
			rangeEnd.setDate(rangeEnd.getDate() + this.columnCount)

			if (dateIsPast(rangeStart) && dateIsPast(rangeEnd)) {
				return 'Archive mode';
			}
			return null
		},
		columnViewType(){
			if (this.columnCount == 1)
				return "full"
			else if (this.columnCount == 3 || this.columnCount == 5)
				return "middle"
			else if (this.columnCount == 7)
				return "short"
			return "full"
		},
		calendarColumnItems() {
			this.selectedDate
			return this.tableHeader.getAllWeek()
		},
		tableHeader() {

			
			if (this.cachedTableHeader) {

				const isEqualFrom = isEqualDate(this.cachedTableHeader.from , this.fromDate)
				const isEqualTo = isEqualDate(this.cachedTableHeader.to , this.toDate)
				if (isEqualFrom && isEqualTo) {
					this.cachedTableHeader.selectedDate = this.selectedDate
					this.cachedTableHeader.columnCount = this.cachedTableHeader.columnCount
					return this.cachedTableHeader
				}
			}

			const tableHeader = new CalendarHeader({
				selectedDate: this.selectedDate,
				columnCount: this.columnCount,
				from: this.fromDate,
				to: this.toDate,
			})

			this.cachedTableHeader = tableHeader

			return tableHeader
		},
		hoursWidth(){
			if (!this.isMounted){
				return 0
			}

			const hoursElem = this.$refs['hours']
			if (!hoursElem) {
				return 0
			}
			return hoursElem.$el.offsetWidth
		},

		columnWidthRaw(){
			if (!this.isMounted){
				return 'none'
			}
			return `calc(100% / ${this.columnCount})`
		},
		columnWidthPxComp(){
			this.columnCount
			this.selectedDate
			this.isCountColumnWidth


			this.$nextTick(() => {
				this.columnWidthPx = this.calcColumnWidth()
			})

			return undefined
		},
		fromDate(){
			return this.dateViewRange.start.date
		},
		toDate(){
			return this.dateViewRange.end.date
		},
	},

	mounted() {

		this.slotContainer = this.$refs['table'].$el
		this.isMounted = true
		this.$nextTick(() => {
			this.columnWidthPx = this.calcColumnWidth()
		})
	},

	data() {
		return {
			slotContainer: null,
			scrollTimer: null,
			columnsCollections: [],
			tableScrollX: 0,
			isMounted: false,
			isCountColumnWidth: false,

			columnWidthPx: 0,
			cachedTableHeader: null,
		};
	},

	beforeDestroy() {
		this.slotContainer = null
	},

	methods: {
		pair: pair,
		syncScroll(){

			let currentCalendar = this.$el
			if (!currentCalendar)
				return
			let currentCalendarTable = this.$el.querySelector('.calendar-v2-table')
			if (!currentCalendarTable)
				return

			let anotherCalendar = Array.from(document.querySelectorAll(".calendar-v2-table"))
				.find(calendar => calendar != this.$el)
			if (!anotherCalendar)
				return

			currentCalendarTable.scrollTo({top: anotherCalendar.scrollTop, left: 0}) 
		},
		calcColumnWidth(){
			const elem = this.$el
			const column = elem.querySelector('.calendar-column')
 			const width = column.offsetWidth

			return Math.round(width)
		},
		onSelectDate(date) {
			this.$emit('on-select-date', date)
		},
	},
};
</script>

<style scoped>

.calendar-v2 {
	position: relative;
	width:  100%;
	height: 100%;
}

.support-message{
	position: fixed;
	top: 25px;
	width: 100%;
	z-index: 4;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
}


</style>