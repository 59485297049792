<template>
	<div class="slot-card__move-behavior" 
	ref="calendar-slot"
	v-bind:class="{
		'is-dragging': !!dragging,
		'is-invalid': timeIsWrong || dateIsWrong,
	}"
	v-bind:style="{...styles}">
		<SlotCardTimeString 
			:slotCard="slotCard" 
			:isVisible="!!dragging"
			:posX="left"
			:isInvalid="timeIsWrong || dateIsWrong"
			:currentHour="currentHour"
			:currentMinute="currentMinute"
			:nearestPlace="nearestPlace"
		/>
		<slot></slot>
	</div>
</template>

<script>
import CalendarWorkTime from '../../../scripts/calendarWorkTime';
import { dateIsPast, isEqualDate, isToday } from '../../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../../scripts/draggable';
import { scrollByCondition } from '../../../scripts/scroll';
import Slot from '../../../scripts/slot';
import SlotCardTimeString from './SlotCardTimeString.vue';
import { calcSlotCardPosY, eventToCoordinates, eventToOffset, eventToPageCoordinates } from '../../../scripts/slotCardPosition';
import TimePeriod from '../../../scripts/timePeriod';




export default {
	name: 'SlotCardMoveBehavior',
	components: {
		SlotCardTimeString
	},

	emits: [
		'on-move-start',
		'on-move',
		'on-move-end'
	],

	props: {
		eventSlot: {
			type: Slot,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54,
		},
		height: {
			type: Number,
			default: 54,
		},
		slotCard: HTMLElement,
		slotContainer: HTMLElement,
		currentHour: {
			type: Number, 
			required: true,
		},
		currentMinute: {
			type: Number, 
			required: true,
		},
		dragDelay: {
			type: Number,
			default: 300,
		},
		scrollStep: {
			type: Number,
			required: true,
		},
		isReplaceable: {
			type: Boolean,
			default: true,
		},
		timeIsWrong: {
			type: Boolean,
			default: false,
		},
		dateIsWrong: {
			type: Boolean,
			default: false,
		},
	},
	
	computed: {

		top(){
			return Math.floor(this.posY)
		},
		left(){
			return Math.floor(this.posX)
		},
		styles(){
			return {
				left: this.left + 'px',
				top: this.top + 'px',
			}
		},
		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		}
	},

	data() {
		return {
			posY: 0,
			originY: 0,
			posX: 0,
			isTouched: false,
			dragging: null,
			dragLimits: null,
			containerSizes: null,
			scrollInterval: null,
			scrollSpeed: 0,
			width: 0,
			futureColumn: null,
			columnsX: null,

			nearestPlace: null,
		};
	},

	watch: {
		slotCard(value){
			if (this.isReplaceable) {
				makeDraggable(this.slotCard, {
					start: this.pointerDownHandler,
				});
			}
		},
	},

	created() {
		this.posY = calcSlotCardPosY(this.eventSlot.period.start.date, this.workTime, this.cellHeight)
		this.originY = this.posY
	},

	mounted(){
		if (this.isReplaceable) {
			makeDraggable(this.slotCard, {
				start: this.pointerDownHandler,
			});
		}
		if (this.slotCard)
			this.width = this.slotCard.offsetWidth
	},
	
	updated(){
		if (this.slotCard)
			this.width = this.slotCard.offsetWidth
	},

	beforeDestroy() {
		if (this.isReplaceable) {
			makeUndraggable(this.slotCard, {
				start: this.pointerDownHandler,
				move: this.pointerMoveHandler,
				end: this.pointerUpHandler,
			});
		}
	},

	methods: {
		containerScrollCheckers(initPos, onScrollDetected, onScrollNotDetected) {
			let isScrolled = false

			const move = (moveEvent) => {
				const move = eventToCoordinates(moveEvent);
				const deltaX = Math.abs(move.x - initPos.x);
				const deltaY = Math.abs(move.y - initPos.y);

				const movedEnough = Math.max(deltaX, deltaY) > 5;
				if (movedEnough) {
					isScrolled = true;
					onScrollDetected()
				}
			}

			const end = () => {
				onScrollNotDetected()
			}

			return {
				move,
				end,
				isScrolled: () => isScrolled,
				enable: () => {
					window.addEventListener('touchmove', move);
					window.addEventListener('touchend', end);
				},
				disable: (names=['touchmove', 'touchend']) => {
					if (names.includes('touchmove'))
						window.removeEventListener('touchmove', move);
					if (names.includes('touchend'))
						window.removeEventListener('touchend', end);
				},
			}
		},

		pointerDownHandler(event) {
			if (this.eventSlot.settings.isQuestionSlot
			||	!this.slotContainer) {
				event.preventDefault();
				return;
			}

			this.isTouched = true;
			const initPos = eventToCoordinates(event);
			const scrollCheckHandlers = this.containerScrollCheckers(initPos, 
				() => {
					scrollCheckHandlers.disable(['touchmove']);
					disableTimeout();		
				}, () => {
					scrollCheckHandlers.disable();
					disableTimeout();
			});

			const timeout = setTimeout(() => {

				// Disable the support listeners
				scrollCheckHandlers.disable();

				// If is no touch or it's scroll, then return
				const isScrolled = scrollCheckHandlers.isScrolled();
				const prepareIsFailed = !this.replacePreparation(event);
				if (!this.isTouched || isScrolled || prepareIsFailed) {
					return;
				}

				// Begin the drag
				this.$emit('on-move-start');
				if (window.navigator.vibrate){
					window.navigator.vibrate(65)
				}

				// Activate the drag
				makeDraggable(this.slotCard, {
					move: this.pointerMoveHandler,
					end: this.pointerUpHandler,
				});

			}, this.dragDelay);

			// Check if the pointer is moved enough to activate the scroll
			const disableTimeout = () => {
				clearTimeout(timeout);
				this.isTouched = false;
			}

			scrollCheckHandlers.enable();
		},

		replacePreparation(event){

			if (!this.slotContainer)
				return false

			// Init table sizes
			let containerSizes = this.slotContainer.getBoundingClientRect()
			let elementSizes = this.$el.getBoundingClientRect()

			let sizes = {}
			sizes["scroll-area"] = this.slotContainer.scrollHeight
			sizes["height"] = Math.round(containerSizes.height - elementSizes.height)
			sizes["width"] = Math.round(containerSizes.width - elementSizes.width)

			const hoursContainer = this.slotContainer.querySelector('.calendar_hours_ruler')
			const hoursContainerSizes = hoursContainer.getBoundingClientRect()
			sizes["left"] = hoursContainerSizes.width + containerSizes.left

			this.containerSizes = sizes

			const limits = {}
			limits["top"] = 0
			limits["bottom"] = this.containerSizes["scroll-area"] - this.height - 10 - 13 - this.cellHeight
			limits["left"] = elementSizes.left * -1 + this.containerSizes["left"]
			limits["right"] = this.containerSizes["width"] + limits["left"] - 30 - 16
			this.dragLimits = limits

			this.columnsX = Array.from(this.slotContainer.querySelectorAll(".calendar-column"))

			const currentColumn = this.columnsX.find((columnElem) => {
				return columnElem.id == `column-${this.eventSlot.period.start.date.toLocaleDateString()}`
			})
			const currentColumnPosX = currentColumn.getBoundingClientRect().left

			this.columnsX = this.columnsX.map((columnElem) => {
				return columnElem.getBoundingClientRect().left - currentColumnPosX
			})
			
			// Init start position of a drag and drop
			const pageCoord = eventToPageCoordinates(event)
			const offset = eventToOffset(event)
			let posX = pageCoord.x - offset.x + this.dragLimits["left"] - 40
			let posY = pageCoord.y - offset.y - containerSizes.top  - 32
			
			// Init start point 
			let {x, y} = eventToCoordinates(event);
			this.dragging = {dx: posX - x, dy: posY - y}

			this.originY = posY

			return true
		},

		pointerMoveHandler(event){
			event.preventDefault();
			if (!this.dragging) {
				return
			}

			let {x, y} = eventToCoordinates(event);
			
			let posX = x + this.dragging.dx
			let posY = y + this.dragging.dy + this.slotContainer.scrollTop

			posX = Math.round(Math.max(this.dragLimits["left"], Math.min(posX, this.dragLimits["right"])))
			posY = Math.round(Math.max(this.dragLimits["top"], Math.min( posY, this.dragLimits["bottom"])))

			let isMovingDown = this.posY < posY
      		this.posY = posY 
			this.posX = posX

			this.scrollContainer(this.posY, isMovingDown)

			this.futureColumn = this.calcFutureColumn(posX)

			if (!this.scrollInterval){
				this.sendMoveEvent(posX, posY)
			}

		},

		sendMoveEvent(posX, posY){
			
			this.$emit('on-move', {
				posX, posY, 
				futureColumnDate: this.futureColumn,
			})

			if (this.slotCollection && this.timeIsWrong) {
				const originDate = this.futureColumn || this.eventSlot.period.start.date
				const date = new Date(originDate)
				date.setHours(this.currentHour, this.currentMinute)
				const period = new TimePeriod(date, -1, this.eventSlot.period.duration)
				this.nearestPlace = this.slotCollection.nearestFreePlace(period)
			} else {
				this.nearestPlace = null
			}
		},

		scrollContainer(posY, isMovingDown){
			let relativeY = Math.round(posY - this.slotContainer.scrollTop)
			
			let scrollBuffer = 0
			let contHeight = this.containerSizes["height"]
			let contScroll = this.containerSizes["scroll-area"]
	

			let tableTopLimiter = (target) => target.scrollTop > 0 && !isMovingDown
			let tableBotLimiter = (target) => {
				return Math.floor(contScroll - contHeight - target.scrollTop - this.height - 50) > 0 && isMovingDown
			}

			/* 
				Interrupt the table scrolling if 
				1. The slot is in the non-border area
				2. The slot is in the top border area and the table is scrolled to the top
				3. The slot is in the bottom border area and the table is scrolled to the bottom
				4. The slot date is today and slot position top is less than the red (current time) line
			*/  
			
			let isNonBorderArea = relativeY - this.cellHeight > scrollBuffer  &&
								relativeY - contHeight + this.cellHeight < scrollBuffer

			let isTopBorderArea = relativeY - this.cellHeight <= scrollBuffer 
								&& !tableTopLimiter(this.slotContainer) && !isMovingDown

			let isBotBorderArea = relativeY - contHeight + this.cellHeight > scrollBuffer 
								&& !tableBotLimiter(this.slotContainer) && isMovingDown

			if (isNonBorderArea || isTopBorderArea || isBotBorderArea) { 
				if (this.scrollInterval){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeed = null
				return
			}

			/*
				Update the scroll speed if the slot is in the border area
			*/

			if (relativeY - this.cellHeight <= scrollBuffer)
				this.scrollSpeed = Math.floor(Math.abs(relativeY - this.cellHeight) / this.scrollStep) * -1
			else if (relativeY - contHeight + this.cellHeight >= scrollBuffer)
				this.scrollSpeed = Math.floor(Math.abs(relativeY - contHeight + this.cellHeight) / this.scrollStep) + 1

			if (this.scrollInterval)
				return
			
			let scrollCondition 

			// Scroll to the top
			if (relativeY - this.cellHeight <= scrollBuffer && this.slotContainer.scrollTop != 0) {
				this.scrollSpeed *= -1
				scrollCondition = (target) => tableTopLimiter(target)
			// Scroll to the bottom
			} else if (relativeY - contHeight + this.cellHeight >= scrollBuffer) {
				scrollCondition = (target) => tableBotLimiter(target)
			} else {
				return 
			}

			// Update the position y and slot time
			let onScroll = (difference) => {
				let futureY = this.posY + this.scrollSpeed
				this.posY = Math.max(0, Math.min(futureY, this.containerSizes["scroll-area"] - this.height - 10))

				this.sendMoveEvent(this.posX, this.posY)
			}

			// Start scrolling using interval
			this.scrollInterval = scrollByCondition(this.slotContainer, () => this.scrollSpeed, scrollCondition, {
				onScroll,
				delay: 15,
				axisY: true,
			})			
		},		

		pointerUpHandler(){

			this.isTouched = false
			this.dragging = null
			this.dragLimits = null

			this.scrollSpeed = null
			if (this.scrollInterval){
				clearInterval(this.scrollInterval)
				this.scrollInterval = null
			}

			const supportsPointerEvents = 'PointerEvent' in window;
			
			if (this.dragging && supportsPointerEvents) {
				this.$el.removeEventListener('pointermove', this.pointerMoveHandler);
			} else if (this.dragging) {
				this.$el.removeEventListener('mousemove', this.pointerMoveHandler);
			}
			
			const lastDragDate = new Date(this.futureColumn)
			lastDragDate.setHours(this.currentHour, this.currentMinute)

			// If future datetime is wrong -> return to the origin position
			if (!(this.timeIsWrong || this.dateIsWrong)) {
				this.posY = calcSlotCardPosY(lastDragDate, this.workTime, this.cellHeight)
			}


			if (isEqualDate(this.futureColumn, this.eventSlot.period.start.date))
				this.posX = 0
			
			this.$emit('on-move-end', {
				futureColumnDate: this.futureColumn,
			})
			// If future column is defined, then change the column
			if (this.futureColumn) {
				this.colorizeFutureColumn(null)
				this.futureColumn = null
			}

			
			makeUndraggable(this.slotCard, {
				move: this.pointerMoveHandler,
				end: this.pointerUpHandler,
			})
		},

		calcFutureColumn(posX){
			if (!this.columnsX || this.columnsX.length == 1)
				return this.eventSlot.period.start.date

			const columnCountLeft = this.columnsX
				.map((value) => value < 0 ? 1 : 0)
				.reduce((acc, value) => acc + value, 0) 
			const closestColumn = this.columnsX.map((columnX, index) => {
				return {
					index: index - columnCountLeft,
					diff: Math.abs(columnX - posX)
				}
			}).sort((a, b) => a.diff - b.diff)[0]

			const closestColumnDate = new Date(this.eventSlot.period.start.date)
			closestColumnDate.setDate(closestColumnDate.getDate() + closestColumn.index)

			this.colorizeFutureColumn(closestColumnDate)
			return closestColumnDate
		},

		colorizeFutureColumn(futureColumn){
			const columnTitles = Array.from(this.slotContainer.querySelectorAll('.calendar-date-title__item'))
			if (!futureColumn) {
				columnTitles.forEach((titleElem) => {
					titleElem.classList.remove('selected')
				})
				return
			}

			columnTitles.forEach((titleElem) => {
				titleElem.classList.remove('selected')
			})

			const columnDate = columnTitles.find((titleElem) => {
				const id = futureColumn.toLocaleDateString()
				return titleElem.id == `calendar-date-title__item-${id}`
			})
			if (!columnDate)
				return
			columnDate.classList.add('selected')
		},
		moveTo(posX, posY){
			if (posX == -1 || posY == -1) {
				this.posX = 0
				this.posY = this.originY
				return
			}

			this.posX = posX
			this.posY = posY
		}
	},
};
</script>

<style scoped>
.slot-card__move-behavior{
	position: absolute;
	height: fit-content;
	left: 0;
	width: 100%;
}

.slot-card__move-behavior.is-dragging{
	outline: 1.5px solid #0075ff;
	border-radius: 3px;
}

.slot-card__move-behavior.is-dragging.is-invalid{
	outline: 1.5px solid #f14668;
}
</style>