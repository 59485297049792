import { dateRange, isEqualDate } from "./date"

class CalendarWeek {
	from
	to

	$cacheRange=null
	$cacheWeekNumber=null

	/**
	 * Make week instance for using in calendar (basically contain 7 days range)
	 * @param {Date} from - start date of week
	 * @param {Date} to	- end date of week 
	 */
	constructor(from, to) {
		this.from = from
		this.to = to
	}

	range(){
		if (!this.$cacheRange)
			this.$rangeCache = dateRange(this.from, this.to)
		return this.$rangeCache
	}

	includes(date){
		return isEqualDate(this.from, date) 
			|| isEqualDate(this.to, date) 
			|| (this.from < date && date < this.to)
	}

	getWeekNumber() {

		if (this.$cacheWeekNumber)
			return this.$cacheWeekNumber

		const date = this.from
		const yearStart = new Date(date.getFullYear(), 0, 1)
		const weekNumber = Math.ceil(((date - yearStart) / 86400000 + yearStart.getDay() + 1) / 7)
		this.$cacheWeekNumber = weekNumber
		return weekNumber
	}
}

export default CalendarWeek