function smartAddEventListener(element, event, handler) {
	if (!element || !event || !handler)
		return

	element.addEventListener(event, handler);
}	

function smartRemoveEventListener(element, event, handler) {
	if (!element || !event || !handler)
		return
	element.removeEventListener(event, handler);
}

function preventer(e){
	e.preventDefault()
}

function makeDraggable(element, handlers={
	start: 	null,
	move: 	null,
	end: 	null,
}, opt={enablePointerEvents: false}) {
	if (!element)
		return
	const supportsPointerEvents = 'PointerEvent' in window;

	if (opt.enablePointerEvents) {
		smartAddEventListener(element, 'pointerdown', handlers.start);
		smartAddEventListener(element, 'pointermove', handlers.move);
		smartAddEventListener(element, 'pointerup', handlers.end);
		smartAddEventListener(element, 'pointercancel', handlers.end);
		smartAddEventListener(element, 'touchstart', preventer);
	} else if (supportsPointerEvents) {
		smartAddEventListener(element, 'touchstart', handlers.start);
		smartAddEventListener(element, 'touchmove', handlers.move);
		smartAddEventListener(element, 'touchend', handlers.end);
		smartAddEventListener(element, 'touchcancel', handlers.end);
	} else {
		smartAddEventListener(element, 'mousedown', handlers.start);
		smartAddEventListener(element, 'mousemove', handlers.move);
		smartAddEventListener(element, 'mouseup', handlers.end);
	}
}

function makeUndraggable(element, handlers={
	start: 	null,
	move: 	null,
	end: 	null,
}, opt={enablePointerEvents: false}) {
	if (!element)
		return
	const supportsPointerEvents = 'PointerEvent' in window;

	if (opt.enablePointerEvents) {
		smartRemoveEventListener(element, 'pointerdown', handlers.start);
		smartRemoveEventListener(element, 'pointermove', handlers.move);
		smartRemoveEventListener(element, 'pointerup', handlers.end);
		smartRemoveEventListener(element, 'pointercancel', handlers.end);
		smartRemoveEventListener(element, 'touchstart', preventer);
	} else if (supportsPointerEvents) {
		smartRemoveEventListener(element, 'touchstart', handlers.start);
		smartRemoveEventListener(element, 'touchmove', handlers.move);
		smartRemoveEventListener(element, 'touchend', handlers.end);
		smartRemoveEventListener(element, 'touchcancel', handlers.end);
	} else {
		smartRemoveEventListener(element, 'mousedown', handlers.start);
		smartRemoveEventListener(element, 'mousemove', handlers.move);
		smartRemoveEventListener(element, 'mouseup', handlers.end);
	}
}

export {
	makeDraggable,
	makeUndraggable
}