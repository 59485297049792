<template>
	<div class="nearest-place-clue" v-if="period" v-bind:style="{
		...style
	}">
			<span class="iconify" 
				data-width="22" 
				data-height="22" 
				data-icon="material-symbols:add-rounded">
			</span>
	</div>
</template>

<script>
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import TimePeriod from '../../scripts/timePeriod';

export default {
	name: 'NearestPlaceClue',

	props: {
		period: {
			type: TimePeriod,
		},
		cellHeight: {
			type: Number,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		}
	},

	computed: {
		top(){
			if (!this.period)
				return 0
			return this.roundPositionByTime(this.period)
		}, 
		height(){
			if (!this.period)
				return 0
			return this.period.duration / 60 * this.cellHeight
		},
		style(){
			if (!this.period)
				return {}

			return {
				top: `${this.top}px`,
				height: `${this.height}px`,
			}
		}
	},

	methods: {
		topPositionByTime(hour, minute){
			return hour * this.cellHeight + minute * this.cellHeight / 60
		},
		roundPositionByTime(period){
			let {hour, minute} = this.workTime.startTime()
			let workStartAlign = this.topPositionByTime(hour, minute)
			const periodStart = period.start.date
			const periodHour = periodStart.getHours()
			const periodMinute = periodStart.getMinutes()
		
			const relativeTop = this.topPositionByTime(periodHour, periodMinute) 
			return relativeTop - workStartAlign
		}
	},
};
</script>

<style scoped>
.nearest-place-clue{
	position: absolute;
	width: 100%;
	left: 0;
	color: #0075ff;
	border: 1.5px dashed #0075ff;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>