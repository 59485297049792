var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dragging),expression:"dragging"}],class:{
	invalid: _vm.timeIsWrong,
	draggable: _vm.dragging,
},style:({
	top			: (_vm.posY + "px"),
	left 		: (_vm.posX + "px"),
	height		: (_vm.cellHeight + "px"),
	width		: (_vm.columnWidth + "px"),
}),attrs:{"id":"create-slot"},on:{"dragstart":function () { return false; }}},[_c('div',{staticClass:"current-time-line",class:{
		invalid: _vm.timeIsWrong
	},style:({
		left: ((-_vm.posX) + "px"),
		right: (_vm.columnWidth + "px"),
	})},[(_vm.nearestPlace)?_c('div',{staticClass:"nearest-time"},[_vm._v(" Free time: "+_vm._s(_vm.neatestPlaceTime)+" ")]):_vm._e(),_c('div',{staticClass:"time-string"},[_vm._v(_vm._s(_vm.time))])])])}
var staticRenderFns = []

export { render, staticRenderFns }