<template>
	<div class="calendar-v2-table" ref="table" @scroll="onScroll">

		<div class="holder"></div>
		<slot name="header"></slot>
		<slot name="hours"></slot>

		<div class="calendar-v2-table__column-section" ref="columns">

			<slot name="columns"></slot>

		</div>
	</div>

</template>

<script>
import { dateDifferenceInDays, isEqualDate } from '../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../scripts/draggable'
import { eventToCoordinates } from '../../scripts/slotCardPosition';

export default {
	name: 'ScrollableTable',

	emits: [
		'on-select-date',
		'on-scroll-x',
		'on-scroll-start',
		'on-scroll-move',
		'on-scroll-end',
	],

	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		columns: {
			type: Array,
			required: true,
		},
		hoursWidth: {
			type: Number,
			required: true,
		},
		columnCount: {
			type: Number,
			required: true,
		}
	},

	computed: {
		tableElement() {
			return this.$refs['table']
		},
	},

	data() {
		return {
			originX: 0,
			lastX: 0,
			selectedIndex: 0,
			isTouched: false,
			columnWidth: 0,
		};
	},

	watch: {
		columnCount(){
			this.$nextTick(() => this.setupColumnWidth())
		},
		columnWidth(){
			this.roundScrollX()
		},
		selectedDate(){
			this.setupSelectedIndex(() => this.roundScrollX())
		}
	},	

	mounted() {
		makeDraggable(this.tableElement, {
			start: this.onTouchStart,
			move: this.onTouchMove,
		})
		this.setupColumnWidth()
		this.tableElement.addEventListener('scrollend', this.onScrollEnd)
		this.roundScrollX(false)
		this.selectedIndex = this.calcSelectedIndex()
	},

	beforeDestroy() {
		makeUndraggable(this.tableElement, {
			start: this.onTouchStart,
			move: this.onTouchMove,
		})
		this.tableElement.removeEventListener('scrollend', this.onScrollEnd)
	},

	methods: {
		onTouchStart(ev) {
			this.isTouched = true
			this.originX = this.$refs['table'].scrollLeft
			this.lastX = this.$refs['table'].scrollLeft
			this.$emit('on-scroll-start', ev)
		},
		
		onTouchMove(ev) {
			// console.log({ev})

			this.$emit('on-scroll-move', ev)
		},
		
		onScrollEnd(ev){
			if (!this.isTouched)
				return
			this.isTouched = false
			const newScrollX = this.$refs['table'].scrollLeft
			const deltaX = Math.round((newScrollX - this.originX) / this.columnWidth)

			if (deltaX != 0) {
				const newDate = new Date(this.selectedDate)
				newDate.setDate(newDate.getDate() + deltaX)
				this.$emit('on-select-date', newDate)				
			} else {
				this.roundScrollX()
			}
			this.lastX = newScrollX
			this.originX = newScrollX
			
			this.$emit('on-scroll-end', ev)
		},

		onScroll(){
			const newScrollX = this.$refs['table'].scrollLeft
			if (this.isTouched && Math.abs(newScrollX - this.lastX) > 0.5) {
				const deltaX = Math.round(newScrollX - this.originX)
				const deltaDates = deltaX / this.columnWidth
				this.$emit('on-scroll-x', {deltaX, deltaDates})
				this.lastX = newScrollX
			}
		},

		roundScrollX(smooth=true){
			if (this.selectedIndex == -1 || this.isTouched)
				return
			
			// const scrollValue = this.columnWidth * this.selectedIndex						

			const columns = Array.from(this.$refs['columns'].querySelectorAll('.calendar-column'))
			const targetColumn = columns[this.selectedIndex]
			const targetColumnLeft = targetColumn.getBoundingClientRect().left

			setTimeout(() => {
				if (this.isTouched) {
					return
				}

				this.$refs['table'].scrollTo({
					left: this.$refs['table'].scrollLeft + targetColumnLeft - this.hoursWidth,
					behavior: smooth ? 'smooth' : 'auto',
				})
			})
		},

		calcSelectedIndex(){
			const selectedDate = this.selectedDate
			const columnItems = this.columns
			const index = columnItems.findIndex(column => isEqualDate(column.date, selectedDate))
			return index
		},

		setupSelectedIndex(onSetupFunc){
			this.selectedIndex = this.calcSelectedIndex()
			if(this.selectedIndex != -1) {
				onSetupFunc()
				return
			}
			let tryCount = 0
			const interval = setInterval(() => {
				this.selectedIndex = this.calcSelectedIndex()
				if (this.selectedIndex != -1) {
					clearInterval(interval)
					onSetupFunc()
				}
				if (tryCount >= 3) {
					clearInterval(interval)
				}
				tryCount++
			}, 50)
		},

		setupColumnWidth(){
			let columnWidth = this.calcColumnWidth()
			if (columnWidth != 0) {
				this.columnWidth = columnWidth
				return 
			}

			let tryCount = 0
			const interval = setInterval(() => {
				let columnWidth = this.calcColumnWidth()
				if (columnWidth != 0) {
					clearInterval(interval)
					this.columnWidth = columnWidth
				}
				if (tryCount >= 3) {
					clearInterval(interval)
				}
				tryCount++
			}, 50)
		},

		calcColumnWidth(){
			if (!this.$refs['columns'] || this.selectedIndex == -1)
				return 0

			const columns = Array.from(this.$refs['columns'].querySelectorAll('.calendar-column'))
			const targetColumn = columns[this.selectedIndex]
			return targetColumn.getBoundingClientRect().width
		}
	},
};
</script>

<style scoped>

.calendar-v2-table__column-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.holder{
	background:white;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 5;
	width: 32px;
	height: 20px;
}

.calendar-v2-table {
	
	overflow: auto;
	padding-right: 16px;
	position: relative;
	padding-bottom: 50px;
	width:  100%;
	height: 100%;

	display: grid;
	grid-template-columns: 32px calc(100% - 32px + 16px);
	grid-template-rows: 20px calc(100% - 20px);
}
.calendar-v2.no-scroll {
  	overflow: hidden;
}

</style>