<template>
	<div class="slot-card-separator">
		<div class="line" v-bind:style="{
			background: color,
		}"></div>
	</div>
</template>

<script>
export default {
	name: 'SlotCardSeparator',
	props: {
		color: {
			type: String,
			default: 'black',
		}
	},
	data() {
		return {
		
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style scoped>
.slot-card-separator{
	position: absolute;
	z-index: 2;
	bottom: -1px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.line {
	height: 1.5px;
	border-radius: 2px;
	width: 50%;
}
</style>