class ScrollOptions {
	constructor(onScroll, delay) {
		this.onScroll = onScroll
		this.delay = delay
	}
}

/**
 * Make scroll of the target function while scrollCondition function return true 
 * @param {HTMLElement} target Target HTML element to scroll 
 * @param {Function} getSpeed Getter for scrolling speed
 * @param {ScrollOptions} opts Contain additional options for the scroll:
 * - delay - delay between executions of the scroll in milliseconds (default 10)
 * - onScroll - function that execute after each one small scroll (default null) 
 * @returns Interval instance
 */
function scrollByCondition(target, getSpeed, scrollCondition, opts={
	onScroll: null, 
	delay: 10,
	axisX: false,
	axisY: false,
}) {
	let speed
	let iterScrollTop = target.scrollTop
	let iterScrollLeft = target.scrollLeft

	let scrollInterval = setInterval(() => {
		if (!scrollCondition(target)) {
			return clearInterval(scrollInterval)
		}

		speed = getSpeed()

		if (opts.axisX) {
			iterScrollLeft += speed
		}
		if (opts.axisY) {
			iterScrollTop += speed
		}
		target.scrollTo(iterScrollLeft, iterScrollTop)

		if (opts.onScroll)
			opts.onScroll(target)
	}, opts.delay)
	return scrollInterval
}

export {
	scrollByCondition
}