<template>
	<SlotCardMoveBehavior ref="move-behavior"
	:eventSlot="eventSlot"
	:workTime="workTime"
	:cellHeight="cellHeight"
	:slotCard="slotCard"
	:slotContainer="slotContainer"
	:isReplaceable="isReplaceable 
		&& !isUpdatingTime && validPageActivity"
	:height="height"
	:currentHour="currentHour"
	:currentMinute="currentMinute"
	:scrollStep="scrollStep"
	:timeIsWrong="timeIsWrong"
	:dateIsWrong="dateIsWrong"
	:neighbors="neighbors"
	@on-move-start="onMoveStart"
	@on-move="onMove"
	@on-move-end="onMoveEnd">

		<slot name="tools-buttons"></slot>

		<slot name="tools"></slot>

		<div class="calendar-slot"
		ref="slot-card"
		:id="`calendar-slot-${eventSlot.index}`"
		v-bind:style="styles"
		v-bind:class="{
			'is-top-neighbor' : neighbors && neighbors['top'] && !isDragging,
			'is-bot-neighbor' : neighbors && neighbors['bot'] && !isDragging,
			'is-dragging' : isDragging || isUpdatingTime,
			'is-past' : isPast,
		}">

			<div class="calendar-slot-content">
				<div class="slot-header" ref="slot-header">
					<div ref="time" class="time">{{ `${currentHour}:${pair(currentMinute)}`}}</div>
					<div ref="part-one" class="name-part-one"></div>
					<div ref="part-two" class="name-part-two"></div>
				</div>

				<div class="slot-footer" v-if="footerString">
					<b-icon :icon="footerIcon" size="is-small"></b-icon>
					<div class="slot-footer__title">
						{{ footerString }}
					</div>
				</div>
			</div>

		</div>		

		<SlotCardSeparator 
			v-if="neighbors && neighbors['bot'] && !isDragging" 
			:color="actionColor()"/>

	</SlotCardMoveBehavior>
</template>

<script>
import CalendarWorkTime from '../../../scripts/calendarWorkTime';
import { dateIsPast, isEqualDate, isEqualTime, pair } from '../../../scripts/date';
import Slot from '../../../scripts/slot';
import SlotCollection from '../../../scripts/slotCollection';
import { getTimeByPosY } from '../../../scripts/slotTime';
import SlotCardMoveBehavior from './SlotCardMoveBehavior.vue';
import { enhanceColor } from '../../../scripts/color';
import { delLastPressedSlot, getLastPressedSlot } from '../../../scripts/lastPressedSlot';
import { getNamePart, getTextWidth } from '../../../scripts/slotCardTitle';
import TimePeriod from '../../../scripts/timePeriod';
import { calcSlotCardPosY } from '../../../scripts/slotCardPosition';
import SlotCardSeparator from './SlotCardSeparator.vue';
import eventBroker from '../eventBus';
import Vue from 'vue';
import { globalBus } from '../../../GlobalEventBus';

const CONFIG = {
	"drag-delay" : 300,
	"movement-step" : 10,
}

const SCROLL_STEPS = 3

export default {
	name: 'SlotCard',
	components: {
		SlotCardMoveBehavior,
		SlotCardSeparator,
	},
	emits: [
		'on-move-invalid-time',
		'on-move-valid-time',
	],
	props: {
		eventSlot: {
			type: Slot,
			required: true,
		},
		slotCollection: SlotCollection,
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54,
		},
		slotContainer: {
			type: HTMLElement,
		},
		slotToolButtons: {
			type: HTMLElement,
		},	
		isReplaceable: {
			type: Boolean,
			default: true,
		},
		columnCount: {
			type: Number,
			default: 1,
		},
		viewType: {
			type: String,
			validation(value){
				return ['full', 'middle', 'short'].includes(value)
			}
		}
	},
	computed: {
		height(){
			return Math.floor(this.cellHeight * this.eventSlot.period.duration / 60)
		},
		styles(){
			const background = this.eventSlot.settings.cellBackgroundColor ?? 'lavender' 
			const textColor = this.eventSlot.settings.cellTextColor ?? 'black'
			let paddingRight = null

			if (this.viewType != 'short' && this.$slots['tools-buttons'] && this.slotToolButtons) {
				paddingRight = this.slotToolButtons.offsetWidth + 7 + 'px'
			}

			return {
				background: background,
				height: `${this.height}px`,
				color: textColor,
				boxShadow: this.shadowIsActive ? 
					`0px 2px 16px 0px ${this.actionColor()}`:
					`0px 0px 0px 0px ${this.actionColor()}`
			}
		},
		scrollStep(){
			return this.cellHeight / SCROLL_STEPS
		},
		isPast(){
			this.updateCount
			return dateIsPast(this.eventSlot.period.end.date)
		},
		footerString(){
			const regular = this.eventSlot.settings.regular
			if (!regular)
				return null
			const chargingRule = regular.chargingRule
			if (chargingRule == 2 && regular.attendingUserId) {
				const attenders = regular.attendingUserId
				.map(id => {
					const attender = this.slotCollection.users.findAttenderById(id)
					return attender ? attender.name : null
				})
				.filter(attender => !!attender)
				return attenders.join(', ')
			} else if (chargingRule == 3 && this.eventSlot.settings.hostId) {
				const hostId = this.eventSlot.settings.hostId
				const host = this.slotCollection.users.findHostById(hostId)
				return host ? host.name : null
			}
			return null
		},
		footerIcon(){
			if (!this.eventSlot.settings.regular)
				return null
			const chargingRule = this.eventSlot.settings.regular.chargingRule
			if (chargingRule == 2) {
				return 'school'
			} else if (chargingRule == 3) {
				return 'account-voice'
			}
			return null
		},
		pageActivity(){
			return this.$store.getters.pageActivity
		},
		validPageActivity(){
			return !this.pageActivity || this.pageActivity == 'move-slot'
		},
	},

	watch: {
		columnCount(){
			if (this.eventSlot.title) {
				this.setupTitleParts(this.eventSlot.title)
			}
		},
	},

	data(){
		return {
			isDragging: false,
			isUpdatingTime: false,
			initialHour: 0,
			currentHour: 0,
			initialMinute: 0,
			currentMinute: 0,

			timeIsWrong: false,
			dateIsWrong: false,

			originScrollY: 0,
			shadowIsActive: false,
			slotCard: null,

			nearestPlace: null,
			neighbors: null,
			updateCount: 0,
		}
	},

	created(){
		this.initialHour = this.eventSlot.period.start.date.getHours()
		this.initialMinute = this.eventSlot.period.start.date.getMinutes()
		this.currentHour = this.initialHour
		this.currentMinute = this.initialMinute
		this.neighbors = this.slotCollection.getNeighbors(this.eventSlot)
		eventBroker.$on(`slot-card-${this.eventSlot.index}`, 'update-neighbors', () => {
			this.neighbors = this.slotCollection.getNeighbors(this.eventSlot)
		})
	},

	mounted(){
		this.handleLastPressedSlot()
		this.slotCard = this.$refs['slot-card']

		let title = this.eventSlot.title
		if (title) {
			this.setupTitleParts(title)
		}
	},

	beforeDestroy() {
		this.slotCard = null
		eventBroker.$off(`slot-card-${this.eventSlot.index}`)
	},

	methods: {
		pair: pair,

		getNamePart: getNamePart,

		setupTitleParts(title){
			if (this.$refs["time"]) {
				let timeWidth = getTextWidth(this.$refs["time"].textContent, 10)
				this.$refs['time'].style.width = `${timeWidth}px`
				// timeWidth += 5
				this.$refs['slot-header'].style.gridTemplateColumns = `${timeWidth}px calc(100% - 5px - ${timeWidth}px)`
			}
			
			const namePartOne = this.getNamePart(this.$refs['part-one'], this.eventSlot.title)
			if (namePartOne)
				this.$refs['part-one'].textContent = namePartOne
			if (namePartOne == title) {
				this.$refs['part-two'].textContent = ""
				return
			}
			title = title.replace(namePartOne, '')
			if (!this.$refs['part-two'])
				return
			this.$refs['part-two'].textContent = title
			if (this.columnCount == 7) {
				this.$refs['part-two'].style.maxWidth = '100%'
			} else if (this.columnCount == 1) {
				this.$refs['part-two'].style.maxWidth = 'calc(100% - 65px)'
			} else {
				this.$refs['part-two'].style.maxWidth = 'calc(100% - 20px)'
			}
		},	

		onMoveStart() {
			this.isDragging = true
			this.initialHour = this.currentHour
			this.initialMinute = this.currentMinute
			this.originScrollY = this.slotContainer.scrollTop
		
			this.$store.dispatch('set-page-activity', 'move-slot')
			globalBus.$emit("static-menu", "update-visible", { visible: false });
		},
		
		onMove({posX, posY, futureColumnDate}){
			const currentDate = this.eventSlot.period.start.date
			const date = futureColumnDate ?? currentDate
			this.setTimeForPosition(posY, date)
		},
		
		onMoveEnd({futureColumnDate}){
			const timeIsChanged = this.initialHour != this.currentHour 
								|| this.initialMinute != this.currentMinute
			this.isDragging = false
			this.$store.dispatch('set-page-activity', null)
			globalBus.$emit("static-menu", "update-visible", {visible:true})
			if (this.timeIsWrong) {
				const date = new Date(futureColumnDate)
				date.setHours(this.currentHour, this.currentMinute, 0, 0)
				const period = new TimePeriod(date, -1, this.eventSlot.period.duration)
				const nearestFreePlace = this.slotCollection.nearestFreePlace(period)
				if (nearestFreePlace) {
					this.moveToNearestFreePlace(nearestFreePlace)
					this.updateTime(futureColumnDate)
				} else {
					this.moveToOrigin()
				}
				this.$emit('on-move-valid-time')
				this.timeIsWrong = false
				this.dateIsWrong = false
				return
			}
			if (timeIsChanged && !this.eventSlot.settings.isQuestionSlot || futureColumnDate)
				this.updateTime(futureColumnDate)
			this.timeIsWrong = false
			this.dateIsWrong = false
		},

		moveToOrigin(){
			this.currentHour = this.initialHour
			this.currentMinute = this.initialMinute

			this.$refs['move-behavior'].moveTo(-1, -1)

			this.slotContainer.scrollTo({
				top: this.originScrollY,
				behavior: 'smooth'
			})
			this.activateSlotShadow()
		},

		moveToNearestFreePlace(nearestFreePlace){
			this.currentHour = nearestFreePlace.start.date.getHours()
			this.currentMinute = nearestFreePlace.start.date.getMinutes()

			let delta = 0

			const currentDate = this.eventSlot.period.start.date
			const futureDate = nearestFreePlace.start.date
			if (!isEqualDate(currentDate, futureDate)) {
				const columns = Array.from(this.slotContainer.querySelectorAll('.calendar-column'))
				const currentColumn = columns.find(column => {
					return column.id == `column-${currentDate.toLocaleDateString()}`
				})
				const futureColumn = columns.find(column => {
					return column.id == `column-${futureDate.toLocaleDateString()}`
				})
				const currentColumnRect = currentColumn.getBoundingClientRect()
				const futureColumnRect = futureColumn.getBoundingClientRect()

				delta = futureColumnRect.left - currentColumnRect.left
			}

			const posX = Math.round(delta)

			// console.log('posX :>> ', posX, "date is changed", dateIsChanged);
			const posY = calcSlotCardPosY(nearestFreePlace.start.date, this.workTime, this.cellHeight)

			this.$refs['move-behavior'].moveTo(posX, posY)

			// Scroll to slot if it's not on the screen
			const viewHeight = this.slotContainer.clientHeight
			const viewScroll = this.slotContainer.scrollTop
			if (posY < viewScroll || posY > viewScroll + viewHeight) {
				const scrollTarget = posY - (viewHeight - 50)/ 2 + this.height / 2
				this.slotContainer.scrollTo({
					top: scrollTarget,
					behavior: 'smooth'
				})
			}

			// this.activateSlotShadow()
		},

		actionColor(){
			const settings = this.eventSlot.settings
			const background = settings && settings.cellBackgroundColor ?
				settings.cellBackgroundColor : '#e6e6fa'

			return enhanceColor(background)
		},

		checkValidTime(futureColumnDate, hour, minute){
			
			const dateIsPast = () => {
				const closestCreateTime = new Date()
				const current = new Date(futureColumnDate)
				current.setHours(hour, minute, 0, 0)
				return closestCreateTime > current	
			}

			const dateIsIntersect = () => {
				const newSlotTime = new Date(futureColumnDate)
				newSlotTime.setHours(hour, minute, 0, 0)

				const originDate = new Date(this.eventSlot.period.start.date)
				originDate.setHours(this.initialHour, this.initialMinute, 0, 0)

				if (isEqualDate(originDate, newSlotTime)
				&& isEqualTime(originDate, newSlotTime))
					return false

				// Escape current time period in calc of busy time
				// If new place inside the current column
				const escapedTimePeriods = isEqualDate(originDate, newSlotTime) ?
					[this.eventSlot.period] : []

				return this.slotCollection.isBusyTime({
					date: newSlotTime,
					duration: this.eventSlot.period.duration,
				}, escapedTimePeriods)
			}

			const timeChecks = [
				{name: 'is-past', check: dateIsPast, disabled: this.workTime.isAllowPastTime},
				{name: 'is-intersect', check: dateIsIntersect}
			]

			const invalidCheck = timeChecks.find(check => {
				if (check.disabled)
					return false
				return check.check()
			})
			if (invalidCheck) {
				const date = new Date(futureColumnDate)
				date.setHours(hour, minute, 0, 0)
				const period = new TimePeriod(date, -1, this.eventSlot.period.duration)

				this.$emit('on-move-invalid-time', {
					reason: invalidCheck.name,
					period: period,
				})
			} else if (this.timeIsWrong) {
				this.$emit('on-move-valid-time')
			}

			this.timeIsWrong = !!invalidCheck
		},

		setTimeForPosition(posY, futureColumnDate){
			const time = getTimeByPosY(posY, this.cellHeight, this.workTime, CONFIG["movement-step"])

			this.checkValidTime(futureColumnDate, time.hour, time.minute)

			this.currentHour = time.hour
			this.currentMinute = time.minute
		},

		updateTime(futureColumn){
			this.isUpdatingTime = true
			const newSlotTime = futureColumn ? 
				new Date(futureColumn) : new Date(this.eventSlot.period.start.date)
			newSlotTime.setHours(this.currentHour, this.currentMinute)
			let browserTZ = this.$store.getters.browserTimezone
            let calendarTZ = this.$store.getters.calendarTimezone
			this.slotCollection.updateSlotTime(this.eventSlot, newSlotTime, browserTZ, calendarTZ)
			.then(data => {
				if (data instanceof Error) {
					this.currentHour = this.initialHour
					this.currentMinute = this.initialMinute
					throw data
				}
				this.initialHour = this.currentHour
				this.initialMinute = this.currentMinute
				this.setupTitleParts(this.eventSlot.title)
				this.updateNeighbors()
				this.updateCount++
			})
			.catch((err) => {
				console.error(err)
				this.moveToOrigin()
			})
			.finally(() => this.isUpdatingTime = false)
		},

		updateNeighbors(){
			if (this.neighbors) {
				const topNeighborId = this.neighbors['top']
				const botNeighborId = this.neighbors['bot']
				if (topNeighborId)
					eventBroker.$emit(`slot-card-${topNeighborId}`, 'update-neighbors')
				if (botNeighborId)
					eventBroker.$emit(`slot-card-${botNeighborId}`, 'update-neighbors')
			}
			this.neighbors = this.slotCollection.getNeighbors(this.eventSlot)
			if (this.neighbors) {
				this.$nextTick(() => {
					const topNeighborId = this.neighbors['top']
					const botNeighborId = this.neighbors['bot']
					if (topNeighborId)
						eventBroker.$emit(`slot-card-${topNeighborId}`, 'update-neighbors')
					if (botNeighborId)
						eventBroker.$emit(`slot-card-${botNeighborId}`, 'update-neighbors')
				})
			}
		},

		handleLastPressedSlot(){
			const lastPressedSlot = getLastPressedSlot()
			if (lastPressedSlot === undefined || lastPressedSlot !== this.eventSlot.index)
				return
			delLastPressedSlot()
			this.activateSlotShadow()
		},

		activateSlotShadow(){
			this.shadowIsActive = true
			setTimeout(() => this.shadowIsActive = false, 1500)
		},

	}
};
</script>

<style scoped>

.icon.is-small {
	height: 1.1em;
}

.icon{
	font-size: 1em;
	display: flex;
	justify-content: flex-start;
}

.calendar-slot{
	position: relative;
	
	border-radius: 3px;
	padding: 3px;

	display: flex;
	justify-content: space-between;

	transition: box-shadow .6s;
}

.is-top-neighbor{
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.is-bot-neighbor{
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.calendar-slot-content{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 10px;	
	width: 100%;
}

.tools-header{
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.is-dragging {
	z-index: 1000;
}

.is-past{
	filter: grayscale(0.5);
}

.slot-header{
	font-size: 10px;
	max-height: 29px;
    display: grid;
	column-gap: 5px;
    grid-template-columns: 6ch calc(100% - 5px - 6ch);
    grid-template-rows: repeat(2, 1fr);

	width: 100%;
}

.slot-header > * {
	line-height: 1.1;
}

.time {
	font-weight: 600;
}


.name-part-two {
	grid-column: span 2 / span 2;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.slot-footer{
	font-size: 9px;
	
	display: flex;
	align-items: center;
}

.slot-footer__title{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex: 1;
}

.slot-tools{
	position: absolute;
	z-index: 4;
	top: 0;
	right: 0;
	left: 0;
}

</style>