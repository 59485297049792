<template>
	<SlotCard
		:eventSlot="eventSlot"
		:slotContainer="slotContainer"
		:workTime="workTime"
		:cellHeight="cellHeight"
		:slotCollection="slotCollection"
		:slotToolButtons="toolsButtons"
		:viewType="viewType"
		:columnCount="columnCount"
		:isEditable="isReplaceable"
		:isReplaceable="isReplaceable"
		:isActive="slotIsActive"
		@on-move-invalid-time="onInvalidTime"
		@on-move-valid-time="onValidTime">

		<!-- <template v-slot:title>
			<div class="slot-title">{{  }}</div>
		</template> -->

		<template v-slot:tools-buttons>
			<BaseSlotToolsButtons v-if="viewType != 'short'"
				:eventSlot="eventSlot"
				:viewType="viewType"
				:slotIsPast="slotIsPast"
				:slotToolsIsOpen="toolsIsOpen"
				@on-edit="openSettings"
				@on-toggle-more="toggleTools"/>
		</template>
		
		<template v-slot:tools>
			<BaseSlotTools
				v-model="toolsIsOpen"
				:eventSlot="eventSlot"
				:slotCollection="slotCollection"
				:cellHeight="cellHeight"
				:viewType="viewType"
				:positionX="toolsPositionX"
				:positionY="toolsPositionY"
			/>
		</template>

	</SlotCard>
</template>

<script>


import { dateIsPast, isEqualDate } from '../../scripts/date';
import { setLastPressedSlot } from '../../scripts/lastPressedSlot';
import SlotCollection from '../../scripts/slotCollection';
import BaseSlotTools from './BaseSlotTools.vue';
import BaseSlotToolsButtons from './BaseSlotToolsButtons.vue';
import eventBroker from './eventBus';
import SlotCard from './slot-card/SlotCard.vue';

const TOOLS_WINDOW_HEIGHT_MIN = 114
const TOOLS_WINDOW_HEIGHT_MAX = 152


export default {
	name: 'BaseSlotCard',
	components: {
		SlotCard,
		BaseSlotTools,
		BaseSlotToolsButtons,
	},
	props: {
		...SlotCard.props,
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		viewType: {
			type: String,
			required: true,
			validation(value){
				return ["full", "middle", "short"].includes(value);
			}
		},
		dateIsSelected: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			toolsIsOpen: false,
			toolsButtons: null,
			slotIsActive: false,
			toolsPositionY: "top",
			toolsPositionX: "right",
			lastInvalidTimeDate: null,
		}
	},

	computed: {
		isBotTools(){

		},
		slotIsPast(){
			return dateIsPast(this.eventSlot.period.start.date)
		},
	},

	mounted(){
		this.toolsButtons = this.$el.querySelector('.tools-container')

	},
	
	methods: {
		toggleTools(){
			this.toolsPositionX = this.dateIsSelected && this.columnCount != 1 ? 
				"left" : "right"
			this.toolsPositionY = this.getSlotToolsPosition()
			this.toolsIsOpen = !this.toolsIsOpen			
		},
		closeTools(){
			this.toolsIsOpen = false
		},
		
		openSettings(){
			if (this.eventSlot.settings.isQuestionSlot)
				return

			setLastPressedSlot(this.eventSlot.index)

			this.$router.push({
				name: "slot-edit", 
				params: {
                	calendarUid: this.$route.params.calendarUid,
                	slotId: this.eventSlot.index,
				}
			})
		},

		positionY(){
			let relativeY = 0
			let currentHour = this.eventSlot.period.start.date.getHours()
			let currentMinute = this.eventSlot.period.start.date.getMinutes()
			let workStartHour = this.workTime.start.getHours()
			let workStartMinute = this.workTime.start.getMinutes()
			relativeY += (currentHour - workStartHour) * this.cellHeight 
			relativeY += (currentMinute - workStartMinute) * this.cellHeight / 60
			return Math.floor(relativeY)
		},

		toolsHeight(){

			let toolsCount = 3

			if (this.viewType != 'full')
				toolsCount += 1
			if (this.slotIsPast)
				toolsCount += 1

			return toolsCount * 38
		},

		getSlotToolsPosition(){
			
			if (!this.slotContainer)
				return "top"

			const slotPosY = this.positionY()
			const slotPosYRel = slotPosY - this.slotContainer.scrollTop + 16.5
			const isEnoughSpaceOnTop = slotPosYRel > this.toolsHeight()
			if (isEnoughSpaceOnTop)
				return "top"
			return "bottom"
		},

		onInvalidTime(data){
			if (!data)
				return
			const {reason, period} = data
			if (!period || reason != 'is-intersect')
				return 
			const currentInvalidTimeDate = new Date(period.start.date)
			
			// Send request to calc nearest free place
			const columnTitle = `column-${currentInvalidTimeDate.toLocaleDateString()}`
			eventBroker.$emit(columnTitle, 'define-nearest-free-place', period)
			
			// If last sended columns is not equal to current
			if (this.lastInvalidTimeDate && !isEqualDate(this.lastInvalidTimeDate, currentInvalidTimeDate)) {
				const lastDateColumnTitle = `column-${this.lastInvalidTimeDate.toLocaleDateString()}`
				eventBroker.$emit(lastDateColumnTitle, 'define-nearest-free-place', null)
			}

			this.lastInvalidTimeDate = new Date(period.start.date)
		},

		onValidTime(){
			if (!this.lastInvalidTimeDate)
				return
			const lastDateColumnTitle = `column-${this.lastInvalidTimeDate.toLocaleDateString()}`
			eventBroker.$emit(lastDateColumnTitle, 'define-nearest-free-place', null)
			this.lastInvalidTimeDate = null
		},
	},
};
</script>

<style scoped>


.button.is-text:hover{
	background: inherit;
}

.is-white{
	color: white;
}

.slot-title{
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>