<template>
	<div class="slot-card__time-string" 
	v-show="isVisible"
	v-bind:class="{ 'is-invalid': isInvalid }"
	v-bind:style="{
		...styles
	}">
		<div class="nearest-time" v-if="nearestPlace">
			Free time: {{ neatestPlaceTime }}
		</div>
		<div class="time-string">
			{{ getTimeString() }}
		</div>
	</div>
</template>

<script>
import { pair } from '../../../scripts/date';
import TimePeriod from '../../../scripts/timePeriod';

export default {
	name: 'SlotCardTimeString',

	props: {
		slotCard: {
			type: HTMLElement,
		},
		isVisible: {
			type: Boolean,
			default: false,
		},
		currentHour: {
			type: Number,
			required: true,
		},
		currentMinute: {
			type: Number,
			required: true,
		},
		posX: {
			type: Number,
			default: 0,
		},
		isInvalid: {
			type: Boolean,
			default: false,
		},
		nearestPlace: {
			type: TimePeriod
		},
	},

	computed: {
		styles() {
			this.posX
			if (!this.slotCard)
				return {}

			const cardRect = this.slotCard.getBoundingClientRect()
			const cardPosX = cardRect.left
			const cardWidth = cardRect.width

			return {
				left: -1 * cardPosX + 'px',
				right: cardWidth / 2 + 'px',
			}
		},
		neatestPlaceTime(){
			if (!this.nearestPlace)
				return null
			const nearestPlaceDate = this.nearestPlace.start.date
			return `${pair(nearestPlaceDate.getHours())}:${pair(nearestPlaceDate.getMinutes())}`
		}
	},

	data() {
		return {
		
		};
	},

	mounted() {
		
	},

	methods: {
		getTimeString() {
			const hour = this.currentHour
			const minute = this.currentMinute
			
			return `${pair(hour)}:${pair(minute)}`
		}
	},
};
</script>

<style scoped>

.slot-card__time-string{
	border-top: 1.5px dashed #0075ff;
	position: absolute;
	top: 0;
	z-index: 4;
	color: #0075ff;
}

.slot-card__time-string.is-invalid{
	border-color: #f14668;
	color: #f14668;
}

.time-string{
	width: fit-content;
	background: white;
	width: 30px;
	text-align: end;
	padding-right: 4px;
	font-size: 10px;
}

.nearest-time{ 
	position: absolute;
	left: 2px;
	top: -24px;
	font-size: 10px;
	z-index: 10;
	background: white;
	border-radius: 4px;
	padding: 2px 4px;
	border: 1px solid #0075ff;
	color: #0075ff;
	width: fit-content;
}
</style>